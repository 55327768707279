import React from 'react';
import PropTypes from 'prop-types';
import { CardActions } from '@mui/material';
import { withStyles } from '@mui/styles';
import withLocalItem from '../../../db/with-local-item';
import ActionButton from '../../action-button';

const styles = {
  actions: {
    alignSelf: 'flex-end',
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

function Footer({ applet: { actions }, classes }) {
  if (!actions) return null;
  return (
    <CardActions className={classes.actions}>
      {
        actions.map((action, j) => {
          const key = `${action.target}-${action.name}-${j}`;
          return <ActionButton action={action} key={key} />;
        })
        }
    </CardActions>
  );
}

Footer.propTypes = {
  applet: PropTypes.shape({
    actions: PropTypes.arrayOf(PropTypes.shape({

    })),
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withLocalItem(withStyles(styles)(Footer));

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';
import miniCardLoader from '../../extensions/loader/views/mini-card';
import defaultStyles from '../../extensions/styles/views/mini-card';
import MiniCardBase from './base';

function InnerMiniCard({
  applet,
  classes,
  ...otherProps
}) {
  const {
    name, description, image, logo,
  } = applet;
  return (
    <MiniCardBase
      imageURL={image && image.contentURL}
      logoURL={logo && logo.contentURL}
      title={name}
      description={description}
      classes={classes}
      {...otherProps}
    />
  );
}

InnerMiniCard.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    logo: PropTypes.shape({
      contentURL: PropTypes.string,
    }),
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    type: PropTypes.string,
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export const DefaultMiniCard = withStyles(defaultStyles)(InnerMiniCard);

function MiniCard(props) {
  const { applet } = props;
  const DefaultComponent = useCallback(() => <DefaultMiniCard {...props} />, [props]);
  const Component = miniCardLoader(applet.type, DefaultComponent);
  return <Component {...props} />;
}

MiniCard.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    logo: PropTypes.shape({
      contentURL: PropTypes.string,
    }),
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    type: PropTypes.string,
  }),
};

MiniCard.defaultProps = {
  applet: {
    name: undefined,
  },
};

export default MiniCard;

import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';

const onlyWithPropAsRequired = propNotAsRequired => (requiredProp, predicate) => branch(
  propNotAsRequired(requiredProp, predicate),
  renderNothing,
);

export default
onlyWithPropAsRequired(
  (requiredProp, predicate) => props => (
    !(props && props[requiredProp]) || (predicate && !predicate(props[requiredProp]))) || false,
);

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';

import { withStyles } from '@mui/styles';
import WidgetsLoader from '../../../../db/widgets-loader';
import withItem from '../../../../db/with-item';
import DynamicItem from '../../../dynamic-item';
import withAppletUrl from '../../../containers/create-url';
import qualifyUrl from '../../../containers/qualify-url';

function WidgetView({
  widget: {
    key, styles, linkto, ...otherProps
  },
}) {
  const WrappedDynamicItem = compose(
    withStyles(styles || {}),
    withItem,
    withAppletUrl,
    qualifyUrl,
  )(DynamicItem);
  const renderLoader = linkto !== 'action' && linkto !== 'none';
  return (
    <>
      {renderLoader && <WidgetsLoader id={key} />}
      <WrappedDynamicItem id={key} linkto={linkto} {...otherProps} />
    </>
  );
}

WidgetView.propTypes = {
  widget: PropTypes.shape({
    key: PropTypes.string,
    styles: PropTypes.shape({ }),
    linkto: PropTypes.string,
  }).isRequired,
};

export default WidgetView;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BottomNavigation,
  BottomNavigationAction, Paper,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  Home as HomeIcon,
  SettingsApplications as SettingsIcon,
  AccountCircleOutlined as UserIcon,
} from '@mui/icons-material';
import useNavigation from 'packages/use-navigation';
import withContainer from './container';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    paddingTop: theme.spacing(1),
  },
}));

const useNavActionClasses = makeStyles({
  root: {
    minWidth: 'fit-content',
  },
});

function YoteNavigation({ actions, user }) {
  const classes = useStyles();
  const navActionClasses = useNavActionClasses();
  const showLabels = useMediaQuery('@media(min-width: 380px)');

  const { Link, location } = useNavigation();
  const navValue = location.pathname === '/' ? 'Home' : location.pathname;
  const [value, setValue] = useState(navValue);
  useEffect(() => {
    setValue(navValue);
  }, [navValue]);

  return (
    <Paper className={classes.root} elevation={2}>
      <BottomNavigation
        showLabels={showLabels}
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        className={classes.nav}
      >
        <BottomNavigationAction
          component={Link}
          to={{
            pathname: '/',
            state: {
              value: 'Home',
            },
          }}
          value="Home"
          label="Home"
          icon={<HomeIcon />}
          classes={navActionClasses}
        />
        <BottomNavigationAction
          component={Link}
          to={{
            pathname: '/settings',
            state: {
              value: 'Settings',
            },
          }}
          value="/settings"
          label="Settings"
          icon={<SettingsIcon />}
          classes={navActionClasses}
        />
        {actions.map(({ label, icon, fabProps }) => {
          const { to, ...restFabProps } = fabProps;
          return (
            <BottomNavigationAction
              key={label}
              icon={icon}
              value={label}
              label={label}
              to={{
                pathname: to,
                state: { value: label },
              }}
              {...restFabProps}
              component={Link}
              classes={navActionClasses}
            />
          );
        })}
        {
          user && user._meta && user._meta.username
          && (
            <BottomNavigationAction
              component={Link}
              to={{
                pathname: `/@${user._meta.username}`,
                state: { value: 'My Stuff' },
              }}
              value={`/@${user._meta.username}`}
              label="My Stuff"
              icon={<UserIcon />}
            />
          )
        }
      </BottomNavigation>
    </Paper>
  );
}
YoteNavigation.propTypes = {
  user: PropTypes.shape({
    _meta: PropTypes.shape({
      username: PropTypes.string,
    }),
  }),
  actions: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.node,
    name: PropTypes.string,
  })),
};
YoteNavigation.defaultProps = {
  actions: [],
  user: undefined,
};
export default withContainer(YoteNavigation);

import React from 'react';
import PropTypes from 'prop-types';
import LeftIcon from '@mui/icons-material/NavigateBefore';
import RightIcon from '@mui/icons-material/NavigateNext';
import SwipeableViews from 'react-swipeable-views';
import { Fab } from '@mui/material';
import { withStyles } from '@mui/styles';
import CarouselItem from './item';

const styles = {
  container: {
    minWidth: '300px',
    maxWidth: '90vw',
    // maxHeight: '90vh',
    display: 'flex',
    position: 'relative',
    backgroundColor: 'black',
  },
  leftNav: {
    position: 'absolute',
    left: 10,
    top: '45%',
    zIndex: 100,
  },
  rightNav: {
    position: 'absolute',
    right: 10,
    top: '45%',
  },
  slideContainer: {
    alignItems: 'center',
  },
};

function Carousel({
  applets, activeIndex, setActiveIndex, classes,
}) {
  return (
    <div className={classes.container}>
      <Fab
        color="primary"
        size="medium"
        disabled={activeIndex === 0}
        onClick={() => {
          if (activeIndex > 0) {
            setActiveIndex(activeIndex - 1);
          }
        }}
        className={classes.leftNav}
      >
        <LeftIcon />
      </Fab>
      <SwipeableViews
        index={activeIndex}
        enableMouseEvents
        containerStyle={styles.slideContainer}
        onChangeIndex={(index) => { setActiveIndex(index); }}
      >
        {
        applets.map((applet, index) => {
          const itemKey = `carousel-item-${applet._meta.appletId}-${index}`;
          return (
            <CarouselItem
              applet={applet}
              key={itemKey}
              className={classes.item}
            />
          );
        })
      }
      </SwipeableViews>
      <Fab
        color="primary"
        size="medium"
        disabled={activeIndex === applets.length - 1}
        onClick={() => {
          if (activeIndex < applets.length - 1) {
            setActiveIndex(activeIndex + 1);
          }
        }}
        className={classes.rightNav}
      >
        <RightIcon />
      </Fab>
    </div>
  );
}

Carousel.propTypes = {
  applets: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  activeIndex: PropTypes.number.isRequired,
  setActiveIndex: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(styles)(Carousel);

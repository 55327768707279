import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import orderBy from 'lodash/orderBy';
import { useEffect } from 'react';
import { useDbContext } from 'App/context/db';
import REDUX_ACTIONS from '../redux/constants';
import ERRORS from '../redux/local-error-applets';
import watchCache from './watch-cache';

function cacheUpdateHandlerFactory(dispatch, id) {
  return (querySnapshot) => {
    if (!querySnapshot.empty) {
      const incomingCache = querySnapshot.docs[0].data();
      if (incomingCache.applets[id]) {
        dispatch({
          type: REDUX_ACTIONS.SET_APPLET,
          id,
          applet: incomingCache.applets[id],
        });
      }

      if (incomingCache.widgets[id]) {
        dispatch({
          type: REDUX_ACTIONS.SET_WIDGETS,
          id,
          widgets: orderBy(incomingCache.widgets[id], 'order'),
        });
      } else {
        const { widgets } = ERRORS.no_widgets_found;
        dispatch({
          type: REDUX_ACTIONS.SET_WIDGETS,
          id,
          widgets,
        });
      }
    }
  };
}

export function useLiveCache(id) {
  const dispatch = useDispatch();
  const db = useDbContext();
  useEffect(() => watchCache(db, {
    id,
    callback: cacheUpdateHandlerFactory(dispatch, id),
  }), [db, dispatch, id]);
  const liveCache = useSelector((state) => ({
    widgets: state.widgets[id],
    applet: state.applets[id],
  }), shallowEqual);
  return liveCache;
}

export function LiveCacheLoader({ id }) {
  useLiveCache(id);
  return null;
}

import compose from 'recompose/compose';
import withState from 'recompose/withState';
import { connect } from 'react-redux';
import withWidgets from '../../../../db/with-widgets';

const mapStateToProps = ({ applets }, { widgets, id }) => {
  const filtered = widgets
    .filter(({ key }) => key !== id)
    .map(widget => applets[`${widget.key}`]);
  return ({
    applets: filtered,
    widgets,
  });
};

export default compose(
  withWidgets,
  connect(mapStateToProps),
  withState('modalOpen', 'setModalOpen', false),
  withState('activeIndex', 'setActiveIndex', 0),
);

import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Header from './header';
import Grid from './grid';
import Footer from './footer';

const useStyles = makeStyles({
  root: {
    display: 'grid',
  },
});

function ImageGrid({ id }) {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <Header id={id} />
      <Grid id={id} />
      <Footer id={id} />
    </Card>
  );
}

ImageGrid.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ImageGrid;

const configurations = {
  'list-item': {
    view: 'list-item',
    list: {
      enabled: true,
      styles: {
        display: 'grid',
        gridGap: '0.6rem',
        gridTemplateColumns: 'repeat( auto-fit, minmax(300px, 1fr) )',
      },
    },
    viewProps: {
      elevation: 1,
    },
  },
  'list-card': {
    view: 'list-card',
    list: {
      enabled: true,
      styles: {
        display: 'grid',
        gridGap: '0.8rem',
        gridTemplateColumns: 'repeat( auto-fit, minmax(300px, 1fr) )',
      },
    },
    viewProps: {
      elevation: 1,
    },
  },
  'mini-card': {
    view: 'mini-card',
    list: {
      enabled: true,
      styles: {
        display: 'grid',
        gridGap: '8px',
        gridTemplateColumns: 'repeat( auto-fit, minmax(200px, 1fr) )',
      },
    },
    viewProps: {
      elevation: 1,
    },
  },
  'panel-list-item': {
    view: 'panel-list-item',
    list: {
      enabled: true,
      styles: {
        display: 'grid',
        gridGap: '0.6rem',
        gridTemplateColumns: 'repeat( auto-fit, minmax(300px, 1fr) )',
        alignItems: 'start',
      },
    },
    viewProps: {
      elevation: 1,
    },
  },
  card: {
    view: 'card',
    list: {
      enabled: true,
      styles: {
        display: 'grid',
        gridGap: '16px',
        gridTemplateColumns: 'repeat( auto-fit, minmax(100%, 1fr) )',
      },
    },
    viewProps: {
      elevation: 1,
    },
    sm: {
      list: {
        enabled: true,
        styles: {
          display: 'grid',
          gridGap: '16px',
          gridTemplateColumns: 'repeat( auto-fit, minmax(500px, 1fr) )',
        },
      },
      viewProps: {
        elevation: 1,
      },
    },
  },
};

const widgetTypes = ['list-item', 'card', 'list-card', 'mini-card', 'panel-list-item'] as const;
type Widget = typeof widgetTypes[number]

type layoutOptions = {
  default: Widget;
  sm: Widget;
  md?: Widget;
  lg?: Widget;
  xl?: Widget;
};

export default function getLayout(id: string, options: layoutOptions) {
  const xs = {
    ...configurations[options.default],
  };
  const sm = {
    ...xs,
    ...configurations[options.sm],
  };
  const md = {
    ...sm,
    ...(options.md && configurations[options.md]),
  };
  const lg = {
    ...md,
    ...(options.lg && configurations[options.lg]),
  };
  const xl = {
    ...lg,
    ...(options.xl && configurations[options.xl]),
  };
  return [{
    key: id,
    xs,
    sm,
    md,
    lg,
    xl,
    ...configurations[options.default],
  }];
}

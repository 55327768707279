import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress, Card } from '@mui/material';
import { withStyles } from '@mui/styles';

const pageStyles = {
  parent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexGrow: 1,
    padding: '8px',
  },
  textPlaceholder: {
    flexGrow: 1,
    padding: '0 16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
  },
  imagePlaceholder: {
    width: '128px',
    height: '128px',
  },
  colorPrimary: {
    backgroundColor: '#E8E8E8',
  },
  barColorPrimary: {
    backgroundColor: '#D8D8D8',
  },
  titlePlaceholder: {
    height: '1.5em',
  },
  descriptionPlaceholder: {
    height: '0.5em',
    marginBottom: '8px',
  },
};

function AppletLoading({ classes }) {
  return (
    <Card elevation={1} className={`${classes.parent} main`}>
      <div>
        <LinearProgress
          className={classes.imagePlaceholder}
          classes={{
            colorPrimary: classes.colorPrimary,
            barColorPrimary: classes.barColorPrimary,
          }}
        />
      </div>
      <div className={classes.textPlaceholder}>
        <LinearProgress
          className={classes.titlePlaceholder}
          classes={{
            colorPrimary: classes.colorPrimary,
            barColorPrimary: classes.barColorPrimary,
          }}
        />
        <br />
        <LinearProgress
          className={classes.descriptionPlaceholder}
          classes={{
            colorPrimary: classes.colorPrimary,
            barColorPrimary: classes.barColorPrimary,
          }}
        />
        <LinearProgress
          className={classes.descriptionPlaceholder}
          classes={{
            colorPrimary: classes.colorPrimary,
            barColorPrimary: classes.barColorPrimary,
          }}
        />
        <LinearProgress
          className={classes.descriptionPlaceholder}
          classes={{
            colorPrimary: classes.colorPrimary,
            barColorPrimary: classes.barColorPrimary,
          }}
        />
      </div>
    </Card>
  );
}

AppletLoading.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

export default withStyles(pageStyles)(AppletLoading);

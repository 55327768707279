import React from 'react';
import { Paper, LinearProgress } from '@mui/material';
import Loaders from '../..';

export function Loading() {
  return (
    <Paper style={{ padding: 16 }}>
      <LinearProgress />
      <LinearProgress />
    </Paper>
  );
}

export const getMiniCardByType = (type, DefaultComponent) => {
  if (Loaders[type] && Loaders[type].views.miniCard) {
    return Loaders[type].views.miniCard();
  } return DefaultComponent || Loaders.Thing.views.miniCard();
};

const loadView = (type, DefaultComponent) => getMiniCardByType(type, DefaultComponent);
export default loadView;

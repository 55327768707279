import React from 'react';
import { Paper, LinearProgress } from '@mui/material';
import Loaders from '../..';

export function Loading() {
  return (
    <Paper style={{ padding: 16 }}>
      <LinearProgress />
      <LinearProgress />
    </Paper>
  );
}

export const getCardByType = (type, DefaultComponent) => {
  if (Loaders[type] && Loaders[type].views.card) {
    return Loaders[type].views.card();
  } return DefaultComponent || Loaders.Thing.views.card();
};

const loadCard = (type, DefaultComponent) => getCardByType(type, DefaultComponent);
export default loadCard;

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'packages/use-navigation/navigation-context';
import { Button } from '@mui/material';

function Btn({
  applet, url, classes, viewProps,
}) {
  let ButtonComp = 'button';
  let target = '';
  let rel = '';
  if (url && url.startsWith('/')) {
    ButtonComp = Link;
  } else if (url) {
    ButtonComp = 'a';
    if (!url.startsWith('tel:')) {
      target = '_blank';
      rel = 'noopener';
    }
  }
  return (
    <Button
      variant={viewProps.variant || 'text'}
      disabled={!url}
      component={ButtonComp}
      href={url}
      rel={rel}
      to={url}
      target={target}
      className={classes && classes.root}
      color={viewProps.color}
    >
      {applet.name}
    </Button>
  );
}

Btn.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  url: PropTypes.string,
  classes: PropTypes.shape({}),
  viewProps: PropTypes.shape({
    variant: PropTypes.string,
    color: PropTypes.string,
  }),
};

Btn.defaultProps = {
  url: undefined,
  classes: {},
  viewProps: {},
};

export default Btn;

import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'packages/use-navigation/navigation-context';
import createURL from 'components/applet-options/edit-toolbar/create-url';

function getNavActions({
  applet, actions = [], auth, user,
}) {
  const acts = [...actions];
  if (applet && applet._meta) {
    if (auth && auth.uid) {
      acts.push({
        icon: <SaveIcon />,
        label: 'Save',
        fixed: true,
        fabProps: {
          component: Link,
          to: `${createURL(applet._meta)}/~add`,
        },
      });
    }
    if (auth && auth.uid === applet._meta.owner) {
      acts.push({
        icon: <EditIcon />,
        label: 'Edit',
        fabProps: {
          component: Link,
          to: `${createURL(applet._meta)}/~`,
        },
      });
    }
  } else if (user) {
    acts.push({
      icon: <AddIcon />,
      label: 'Create',
      fixed: true,
      fabProps: {
        component: Link,
        to: `${createURL(user._meta)}/~create`,
        color: 'secondary',
      },
    });
  }
  return acts;
}
export default getNavActions;

import { useSelector } from 'react-redux';

function useLocalOwnershipCheck(appletKey: string) {
  return useSelector((state) => {
    try {
      const { auth, applets } = state as {auth:any, applets:any};
      return auth.uid === applets[appletKey]._meta.owner;
    } catch {
      return false;
    }
  });
}
export default useLocalOwnershipCheck;

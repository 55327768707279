import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import ImageViewLoader from '../../extensions/loader/views/image-viewer';
import Base from './base';

function InnerImageViewer({
  applet: {
    image, name, description,
  },
  ...otherProps
}) {
  return (
    <Base
      imageURL={image && image.contentURL}
      primary={name}
      secondary={description}
      {...otherProps}
    />
  );
}

InnerImageViewer.propTypes = {
  applet: PropTypes.shape({
    image: PropTypes.shape({
      contentURL: PropTypes.string.isRequired,
    }),
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
  }).isRequired,
};

function ImageViewer(props) {
  const { applet } = props;
  const DefaultComponent = useCallback(() => <InnerImageViewer {...props} />, [props]);
  const Component = ImageViewLoader(applet.type, DefaultComponent);
  return (<Component {...props} />);
}

ImageViewer.propTypes = {
  applet: PropTypes.shape({
    image: PropTypes.shape({
      contentURL: PropTypes.string.isRequired,
    }),
    type: PropTypes.string,
    name: PropTypes.string.isRequired,
  }).isRequired,
  url: PropTypes.string,
  viewProps: PropTypes.shape({}),
};

ImageViewer.defaultProps = {
  url: undefined,
  viewProps: {},
};

export default ImageViewer;

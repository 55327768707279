import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import setPropTypes from 'recompose/setPropTypes';
import defaultProps from 'recompose/defaultProps';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import AppContext from '../../../../App/context';

export const useContextualAppletURL = (applet, linkto) => {
  const { ownerName, appId } = useContext(AppContext);
  if (applet && linkto === 'applet' && applet._meta) {
    let appletUrl;
    if (applet.type === 'User') {
      appletUrl = `/@${applet._meta.username}`;
    } else if (applet._meta.appId === applet._meta.appletId) {
      appletUrl = `/@${applet._meta.ownerName}/${applet._meta.appId}`;
    } else {
      appletUrl = `/@${applet._meta.ownerName}/${applet._meta.appId}/${applet._meta.appletId}`;
    }
    if (ownerName && appId
       && ownerName === applet._meta.ownerName && appId === applet._meta.appId) {
      if (appletUrl === `/@${applet._meta.ownerName}/${applet._meta.appId}`) {
        appletUrl = '/';
      } else appletUrl = appletUrl.replace(`/@${applet._meta.ownerName}/${applet._meta.appId}`, '');
    }
    if (ownerName && appId
       && ownerName === applet._meta.ownerName && appId !== applet._meta.appId) {
      appletUrl = `/${applet._meta.appId}/${applet._meta.appletId}`;
    }

    if (window && window.location.pathname === appletUrl) {
      return '';
    }

    return appletUrl;
  }

  if (linkto === 'action' && applet.actions && applet.actions[0]) {
    return `${applet.actions[0].target}`;
  }

  return '';
};

export default compose(
  setPropTypes({
    applet: PropTypes.shape({
      _meta: PropTypes.shape({}),
    }).isRequired,
    linkto: PropTypes.oneOf(['none', 'applet', 'action']),
  }),
  defaultProps({
    linkto: 'applet',
  }),
  withProps(({ applet, linkto }) => ({
    applet,
    url: useContextualAppletURL(applet, linkto),
  })),
);

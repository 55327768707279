import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { Modal } from '@mui/material';
import { withStyles } from '@mui/styles';
import GridItem from './item';
import Carousel from './carousel';
import withContainer from './container';
import ItemFetcher from '../../../db/ItemFetcher';

const styles = {
  root: {
    display: 'grid',
    flexWrap: 'wrap',
    gridGap: '4px',
    gridTemplateRows: 'repeat( auto-fit, 128px )',
    gridTemplateColumns: 'repeat( auto-fit, minmax(128px, 1fr) )',
    '@media (min-width: 768px)': {
      gridTemplateRows: 'repeat( auto-fit, 200px )',
      gridTemplateColumns: 'repeat( auto-fit, minmax(200px, 1fr) )',
    },
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  carousel: {
    width: '80%',
  },
};
function Grid({
  applets, classes,
  modalOpen, activeIndex,
  setModalOpen, setActiveIndex, widgets,
}) {
  const childrenApplets = applets
    .filter(applet => applet && applet.image);
  return (
    <div className={classes.root}>
      {widgets.map(({ key }) => <ItemFetcher id={key} key={key} />)}

      {
     childrenApplets.map((applet, index) => {
       const itemKey = `grid-item-${applet._meta.appletId}-${index}`;
       return (
         <GridItem
           key={itemKey}
           applet={applet}
           onClick={() => {
             setModalOpen(true);
             setActiveIndex(index);
           }}
         />
       );
     })
    }
      <Modal
        open={modalOpen}
        onClose={() => { setModalOpen(false); }}
        disableAutoFocus
        className={classes.modal}
      >
        <Carousel
          applets={childrenApplets}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          className={classes.carousel}
        />
      </Modal>
    </div>
  );
}
Grid.propTypes = {
  applets: PropTypes.arrayOf(
    PropTypes.shape({}),
  ).isRequired,
  widgets: PropTypes.arrayOf(
    PropTypes.shape({}),
  ).isRequired,
  classes: PropTypes.shape({}).isRequired,
  setActiveIndex: PropTypes.func.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  activeIndex: PropTypes.number.isRequired,
};

export default compose(
  withStyles(styles),
  withContainer,
)(Grid);

import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import withLocalApplet from '../../../db/with-local-item';

function MenuTitle({ applet }) {
  return (
    <Typography variant="h6">
      {applet.name}
    </Typography>
  );
}

MenuTitle.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

export default withLocalApplet(MenuTitle);

import React, { useState } from 'react';
import {
  Typography, useMediaQuery, Menu, MenuItem,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import DropDownIcon from '@mui/icons-material/ArrowDropDown';
import EyeIcon from '@mui/icons-material/RemoveRedEye';

export { default as getLayout } from './get-layout';

const widgetTypes = ['list-item', 'card', 'list-card', 'mini-card', 'panel-list-item'] as const;
type Widget = typeof widgetTypes[number]

const widgets: { [key: string]: string } = {
  'list-item': 'List Item',
  card: 'Card',
  'panel-list-item': 'Expandable',
  'list-card': 'List Card',
  'mini-card': 'Mini Card',
};

const useStyles = makeStyles({
  root: {
    padding: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  select: {
    display: 'flex',
    alignItems: 'center',
  },
});
type Props = {
  value: { default: Widget, sm: Widget, },
  onChange: (value: { default: string; sm: string }) => void
}

function LayoutSwitcher({ value, onChange }: Props) {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:600px)');
  const label = isMobile ? widgets[value.default] : widgets[value.sm];
  const [anchorEl, setAnchorEl] = useState();
  const [open, setOpen] = useState(false);
  const handleOpen = ({ target }: any) => {
    setAnchorEl(target);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const activeValue = isMobile ? value.default : value.sm;
  const activeKey = isMobile ? 'default' : 'sm';
  return (
    <div className={classes.root}>
      <Typography variant="caption" className={classes.select} onClick={handleOpen}>
        <EyeIcon />
        {` ${label} `}
        <DropDownIcon />
      </Typography>
      <Menu anchorEl={anchorEl || null} open={open} onClose={handleClose}>
        {
          Object.keys(widgets).map((key) => {
            const widgetName = widgets[key];
            return (
              <MenuItem
                onClick={() => {
                  onChange({ ...value, [activeKey]: key });
                  handleClose();
                }}
                value={key}
                selected={key === activeValue}
                key={key}
              >
                {widgetName}
              </MenuItem>
            );
          })
        }
      </Menu>
    </div>
  );
}

export default LayoutSwitcher;

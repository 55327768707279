import React from 'react';
import PropTypes from 'prop-types';
import {
  ListSubheader, Avatar,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useLocalApplet } from 'packages/db/with-local-item';
import useNavigation from 'packages/use-navigation';
import { useContextualAppletURL } from '../../containers/create-url';

const useStyles = makeStyles({
  root: {
    paddingLeft: 5,
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
  avatar: {
    margin: 10,
  },
});

function Header({ id }) {
  const applet = useLocalApplet(id);
  const url = useContextualAppletURL(applet, 'applet');
  const classes = useStyles();
  const { Link } = useNavigation();
  return (
    <ListSubheader
      component={Link}
      to={url}
      className={classes.root}
    >
      {
        applet.image
        && (
          <Avatar
            src={applet.image.contentURL}
            alt=""
            className={classes.avatar}
          />
        )
      }
      {applet.name}
    </ListSubheader>
  );
}

Header.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Header;

import React from 'react';
import withLocalWidgets from '../with-local-widgets';
import ListWidgetsLoader from './list-loader';

const WidgetsLoader = ({ widgets }) => widgets.map((widget) => {
  if (widget.list && widget.list.enabled) {
    return <ListWidgetsLoader key={widget.key} id={widget.key} />;
  } return null;
});

export default withLocalWidgets(WidgetsLoader);

import db from '../../../../../db';

export default async (_oldUsername, _newUsername, _appId) => {
  const userAppletRawDocs = await db.query(
    `users/${_oldUsername}/apps/${_appId}/applets`,
    [],
  );

  if (!userAppletRawDocs.length) {
    return [];
  }

  const toBeDeletedApplets = [...userAppletRawDocs]
    .map(({ id }) => ({
      id,
      collection: `users/${_oldUsername}/apps/${_appId}/applets`,
      action: 'delete',
    }));

  const toBeCreatedApplets = [...userAppletRawDocs]
    .map(({ id, data }) => ({
      id,
      action: 'create',
      data: {
        appletKey: data.appletKey,
        _meta: {
          owner: data._meta.owner,
          updated: Math.round(Date.now() / 1000),
        },
      },
      collection: `users/${_newUsername}/apps/${_appId}/applets`,
    }));

  return [...toBeDeletedApplets, ...toBeCreatedApplets];
};

import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useNavigation from 'packages/use-navigation';
import Image from 'components/image';
import { useContextualAppletURL } from '../../../../containers/create-url';

const useStyles = makeStyles({
  root: {
    maxHeight: '90vh',
    backgroundColor: 'black',
    display: 'flex',
  },
  wrapper: {
    position: 'relative',
    display: 'flex',
    backgroundColor: 'black',
    margin: '0 auto',
    alignItems: 'center',
  },
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
    backgroundColor: 'white',
    margin: '0 auto',
    height: 'auto',
    width: 'auto',
  },
  content: {
    padding: '8px',
    position: 'absolute',
    right: '0',
    bottom: '0',
    left: '0',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
  },
  subheading: {
    textDecoration: 'none',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
});

function CarouselItem({ applet }) {
  const { image, name } = applet;
  const url = useContextualAppletURL(applet, 'applet');
  const classes = useStyles();
  const { Link } = useNavigation();
  return (
    <div
      className={classes.root}
    >
      <div className={classes.wrapper}>
        <Image
          className={classes.img}
          src={image.contentURL}
          alt=""
        />
        <div className={classes.content}>
          <Typography
            component={Link}
            to={url}
            variant="subtitle1"
            color="inherit"
            className={classes.subheading}
          >
            {name}
          </Typography>
        </div>
      </div>
    </div>
  );
}

CarouselItem.propTypes = {
  applet: PropTypes.shape({
    image: PropTypes.shape({
      contentURL: PropTypes.string,
    }),
    name: PropTypes.string,
  }).isRequired,
  classes: PropTypes.shape({
  }).isRequired,
};

export default CarouselItem;

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';
import styles from '../../extensions/styles/views/card';
import cardLoader from '../../extensions/loader/views/card';
import CardBase from './base';

function DefaultCardView({
  applet,
  classes,
  ...otherProps
}) {
  const {
    name, description, image, actions, logo, customProperties,
  } = applet;
  return (
    <CardBase
      image={image}
      logo={logo}
      title={name}
      description={description}
      actions={actions}
      customProperties={customProperties}
      classes={classes}
      {...otherProps}
    />
  );
}

DefaultCardView.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    logo: PropTypes.shape({
      contentURL: PropTypes.string,
    }),
    customProperties: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.node,
      unit: PropTypes.node,
      label: PropTypes.node,
    })),
    actions: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      target: PropTypes.string,
    })),
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

const StyledDefaultCardView = withStyles(styles)(DefaultCardView);

function CardView(props) {
  const { applet } = props;
  const DefaultComponent = useCallback(() => <StyledDefaultCardView {...props} />, [props]);
  const Component = cardLoader(applet.type, DefaultComponent);
  return <Component {...props} />;
}

CardView.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    actions: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      target: PropTypes.string,
    })),
    type: PropTypes.string,
  }),
};

CardView.defaultProps = {
  applet: {
    name: undefined,
    description: undefined,
  },
};

export default CardView;

import Head from 'next/head';
import React from 'react';

type Props = {
  title: string;
  description: string;
  favicon: string;
  image: string;
  url: string;
};

function AppHead({
  title, description, favicon = '', image = '', url = '',
}: Props) {
  return (
    <Head>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {favicon && <link rel="icon" href={favicon} />}
      {image && <meta name="image" content={image} />}
      {url && <meta property="og:url" content={url} />}
    </Head>
  );
}

export default AppHead;

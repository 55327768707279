import React from 'react';
import compose from 'recompose/compose';
import renameProp from 'recompose/renameProp';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import WidgetView from '../single';
import withWidgets from '../../../../db/with-widgets';
import withLocalApplet from '../../../../db/with-local-item';

function WidgetList({
  widgets, rootWidget, rootApplet, classes,
}) {
  const widgetKeyExclusions = [rootWidget.key];
  if (!rootWidget.list.showApp) widgetKeyExclusions.push(rootApplet._meta.app);
  if (!rootWidget.list.showParent) widgetKeyExclusions.push(rootApplet._meta.parent);
  return (
    <div className={classes.root}>
      {
        rootWidget.list.title
          && (
          <Typography variant="h5" style={{ margin: '24px 4px 0px 4px' }}>
            {rootApplet.name}
          </Typography>
          )
      }
      <div className={classes.list}>
        {
          widgets.filter(widget => !widgetKeyExclusions.includes(widget.key))
            .map((widget) => {
              const reactKey = `${rootWidget.key}-${widget.key}`;
              return (
                <WidgetView
                  key={reactKey}
                  widget={{
                    ...widget,
                    styles: {},
                    view: rootWidget.view,
                    linkto: rootWidget.linkto,
                    viewProps: rootWidget.viewProps,
                  }}
                />
              );
            })
        }
      </div>
    </div>
  );
}

WidgetList.propTypes = {
  widgets: PropTypes.arrayOf(PropTypes.shape({})),
  rootWidget: PropTypes.shape({
    list: PropTypes.shape({
      showApp: PropTypes.bool,
      title: PropTypes.bool,
      showParent: PropTypes.bool,
    }),
    linkto: PropTypes.string,
    view: PropTypes.string,
    viewProps: PropTypes.shape({ }),
    key: PropTypes.string,
  }).isRequired,
  rootApplet: PropTypes.shape({
    name: PropTypes.string,
    _meta: PropTypes.shape({
      app: PropTypes.string,
      parent: PropTypes.string,
    }),
  }).isRequired,
  classes: PropTypes.shape({}),
};

WidgetList.defaultProps = {
  widgets: [],
  classes: {},
};

export default compose(
  withWidgets,
  withLocalApplet,
  renameProp('applet', 'rootApplet'),
)(WidgetList);

import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Toolbar } from '@mui/material';
import MenuTitle from './title';
import MenuItems from './items';

function AppMenu({ id }) {
  return (
    <AppBar position="sticky" color="default">
      <Toolbar>
        <MenuTitle id={id} />
      </Toolbar>
      <MenuItems id={id} />
    </AppBar>
  );
}

AppMenu.propTypes = {
  id: PropTypes.string.isRequired,
};

export default AppMenu;

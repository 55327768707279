import React from 'react';
import useApplet from '../use-applet';

type propTypes = {
  id: string;
  [key: string]: any;
};

function withItem(Component: React.ComponentType<any>) {
  return function (props: propTypes) {
    const { id } = props;
    const applet = useApplet(id);
    if (!applet) {
      return null;
    }
    return <Component applet={applet} {...props} />;
  };
}
export default withItem;

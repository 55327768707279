import React from 'react';
import loadable from 'packages/react-loadable';
import { Paper, LinearProgress } from '@mui/material';
import Loaders from '../..';

export function Loading() {
  return (
    <Paper style={{ padding: 16 }}>
      <LinearProgress />
      <LinearProgress />
    </Paper>
  );
}

export const getImageViewerByType = (type, DefaultComponent) => {
  if (Loaders[type] && Loaders[type].views.imageViewer) {
    return Loaders[type].views.imageViewer;
  } return DefaultComponent ? () => new Promise(DefaultComponent) : Loaders.Thing.views.imageViewer;
};

export default (type, DefaultComponent) => loadable({
  loading: DefaultComponent || Loading,
  loader: getImageViewerByType(type, DefaultComponent),
});

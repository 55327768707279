import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import WidgetsFetcher from '../applet-widgets-fetcher';

export function useLocalWidgets(id) {
  return useSelector(state => state.widgets[id], shallowEqual);
}

const renderAppletWidgetsFetcherIfNoLocalWidgets = C => (function (p) {
  const { id } = p;
  const widgets = useLocalWidgets(id);
  return widgets ? <C {...p} widgets={widgets} /> : <WidgetsFetcher id={id} />;
});

export default renderAppletWidgetsFetcherIfNoLocalWidgets;

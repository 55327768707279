import React from 'react';
import { makeStyles } from '@mui/styles';
import WidgetsView from 'packages/view-widgets/widgets';
import AppletLoading from 'packages/view-widgets/page-loading';
import { useLiveCache } from 'packages/db/with-live-cache';
import { useLocalWidgets } from 'packages/db/with-local-widgets';
import useLiveApplet from 'packages/db/use-live-applet';
import { useAppletKeyFromPath } from 'packages/db/with-appletKey';
import { useNavigationContext } from 'packages/use-navigation/navigation-context';
import useApplet from 'packages/db/use-applet';
import LinkPage from 'components/link-page';
import Navigation from './navigation';
import AppHead from '../head';

const useStyles = makeStyles(theme => ({
  appletLoadingContainer: {
    gridArea: 'main',
    marginTop: theme.spacing(1),
  },
}));

function AppViewSelector({ id, widgets }) {
  const appType = process.env.NEXT_PUBLIC_APP_TYPE;
  if (appType === 'Linkist') {
    return <LinkPage id={id} />;
  }
  return (
    widgets && <WidgetsView widgets={widgets} />
  );
}
function LiveLoader({ id }) {
  const [permissionError, setPermissionError] = React.useState(false);
  useLiveCache(id);
  useLiveApplet(id, {
    onError: (e) => {
      if (e.code === 'permission-denied') {
        setPermissionError(true);
      }
    },
  });
  if (permissionError) {
    return <div>Permission Denied</div>;
  }
  return null;
}

function Applet(props) {
  const classes = useStyles();
  const id = useAppletKeyFromPath(props);
  const applet = useApplet(id);
  const widgets = useLocalWidgets(id);
  const { location: { pathname } } = useNavigationContext();
  return (
    <>
      {id && <LiveLoader id={id} />}
      {pathname.startsWith('/@') && applet && <AppHead title={applet.name} description={applet.description} />}
      {
        widgets || id
          ? <AppViewSelector id={id} widgets={widgets} />
          : (
            <div className={classes.appletLoadingContainer}>
              <AppletLoading />
            </div>
          )
      }
      {pathname.startsWith('/@') && applet && <Navigation applet={applet} id={id} />}
    </>
  );
}

export default Applet;

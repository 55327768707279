import React from 'react';
import PropTypes from 'prop-types';
import Image from 'components/image';
import { withStyles } from '@mui/styles';

const styles = {
  root: {
    backgroundColor: 'black',
    display: 'inline-grid',
    position: 'relative',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  img: {
    maxHeight: '100%',
    minHeight: 128,
    '@media (min-width: 768px)': {
      minHeight: 200,
    },
    width: '100%',
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
    backgroundColor: 'white',
    objectFit: 'cover',
  },
};

function GridItem({ applet: { image }, classes, onClick }) {
  return (
    <div
      className={classes.root}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyUp={null}
    >
      <Image
        className={classes.img}
        src={image.contentURL}
        alt=""
      />
    </div>
  );
}

GridItem.propTypes = {
  applet: PropTypes.shape({
    image: PropTypes.shape({
      contentURL: PropTypes.string,
    }),
  }).isRequired,
  classes: PropTypes.shape({
  }).isRequired,
  onClick: PropTypes.func,
};

GridItem.defaultProps = {
  onClick: undefined,
};

export default withStyles(styles)(GridItem);

import React from 'react';
import PropTypes from 'prop-types';
import useAuth from 'packages/auth/use-auth';
import useApplet from 'packages/db/use-applet';
import Navigation from 'components/navigation';
import getNavActions from './get-nav-actions';

function AppletNavigation({ applet, actions: defaultActions }) {
  const auth = useAuth();
  const user = useApplet(auth?.uid);
  const actions = getNavActions({
    applet, actions: defaultActions, auth, user,
  });
  return <Navigation user={user} actions={actions} />;
}
AppletNavigation.propTypes = {
  applet: PropTypes.shape({}),
  actions: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.node,
    name: PropTypes.string,
  })),
};
AppletNavigation.defaultProps = {
  actions: [],
  applet: undefined,
};
export default AppletNavigation;

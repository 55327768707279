import React, { Suspense } from 'react';

function loadable({ loading, loader }) {
  const LoadableComponent = React.lazy(loader);
  const Loading = loading;
  return function (props) {
    return (
      <Suspense fallback={<Loading {...props} />}>
        <LoadableComponent {...props} />
      </Suspense>
    );
  };
}

export default loadable;

import { useDbContext } from 'App/context/db';
import { useEffect, useState } from 'react';
import validateUsername from '../validate-username';

const removeAllNonAllowedCharacters = str => `${str}`.replace(/[^0-9a-zA-Z-_.]/g, '');

export function useUsernameUpdater(val, onValidate, onChange) {
  const [value, setValue] = useState(val);
  const db = useDbContext();
  useEffect(() => {
    validateUsername(db, value, (hint) => {
      onValidate(hint);
      onChange(value);
    });
  });
  function changeHandler(e) {
    const cleanValue = removeAllNonAllowedCharacters(e.target.value);
    onValidate({
      error: true,
      message: '',
    });
    validateUsername(db, cleanValue, (hint) => {
      onValidate(hint);
      if (!hint.error) {
        onChange(cleanValue);
      }
    });
    setValue(cleanValue);
  }

  return { value, changeHandler };
}
export default useUsernameUpdater;

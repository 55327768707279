import React from 'react';
import PropTypes from 'prop-types';
import { Typography, DialogActions } from '@mui/material';
import UsernameInput from './username-input';
import SubmitButton from '../submit-button';
import withContainer from './container';

const styles = {
  container: {
    padding: '12px',
  },
  input: {
    marginTop: '7px',
    marginBottom: '20px',
  },
};

function Form({
  user: { _meta }, onValueChange, hint, saveHandler, validationHandler, isValid, onClose,
}) {
  return (
    <div style={styles.container}>
      <Typography variant="h5">Pick a username</Typography>
      <div style={styles.input}>
        <UsernameInput
          onChange={onValueChange}
          value={
            (_meta && _meta.username)
            // || removeAllNonAllowedCharacters(auth.email)
          }
          hint={hint}
          onValidate={validationHandler}
        />
      </div>
      <DialogActions>
        {
      onClose
      && (
      <SubmitButton
        text="Cancel"
        variant="text"
        onClick={onClose}
      />
      )
    }
        <SubmitButton
          text="Save"
          variant="text"
          onClick={saveHandler}
          disabled={!isValid}
        />
      </DialogActions>
    </div>
  );
}

Form.propTypes = {
  user: PropTypes.shape({
    _meta: PropTypes.shape({
      username: PropTypes.string,
    }),
  }).isRequired,
  auth: PropTypes.shape({
    email: PropTypes.string,
  }),
  hint: PropTypes.shape({}),
  isValid: PropTypes.bool,
  onValueChange: PropTypes.func.isRequired,
  validationHandler: PropTypes.func.isRequired,
  saveHandler: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};
Form.defaultProps = {
  auth: { email: '' },
  hint: undefined,
  isValid: false,
  onClose: undefined,
};

export default withContainer(Form);

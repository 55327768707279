import { connect } from 'react-redux';
import compose from 'recompose/compose';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';
import mapProps from 'recompose/mapProps';
import REDUX_ACTIONS from '../../../db/redux/constants';
import db from '../../../db';
import withNotifications from '../../../../notifications-context/consumer';
import cleanUpUserCollection from '../cleanup-user-collection';

const onValueChange = ({ setUser, user }) => (value) => {
  setUser({ ...user, _meta: { ...user._meta, username: value, _username: value.toUpperCase() } });
};

const validationHandler = ({ setValid, setHint }) => (validation) => {
  setValid(validation && !validation.error);
  setHint(validation);
};
const saveHandler = ({
  user, notify, oldUsername, history, dispatch,
}) => async () => {
  const applet = {
    id: user.uid,
    data: {
      ...user,
      _meta: {
        ...user._meta,
        _username: user._meta.username.toUpperCase(),
      },
    },
  };
  let userCollectionDoc = [];
  if (!oldUsername) {
    userCollectionDoc = [
      {
        id: user._meta.username.toUpperCase(),
        data: { uid: user.uid, _meta: { owner: user.uid } },
        collection: 'users',
        action: 'create',
      },
    ];
  }

  const userApplets = await db.query(
    'applets',
    [
      ['_meta.owner', '==', user.uid],
    ],
  );

  const cleanedUpUserApplets = [...userApplets]
    .filter(({ data: { type } }) => type !== 'User')
    .map(({ id, data }) => ({
      id,
      collection: 'applets',
      data: {
        ...data,
        _meta: {
          ...data._meta,
          ownerName: user._meta.username,
          _ownerName: `${user._meta.username}`.toUpperCase(),
        },
      },
    }));

  let cleanedUpUserCollection = [];
  if (oldUsername) {
    cleanedUpUserCollection = await cleanUpUserCollection(
      oldUsername.toUpperCase(),
      user._meta.username.toUpperCase(),
    );
  }
  return db.batch([
    applet,
    ...userCollectionDoc,
    ...cleanedUpUserCollection,
    ...cleanedUpUserApplets,
  ]).then(async () => {
    if (oldUsername) {
      dispatch({
        id: user.uid,
        type: REDUX_ACTIONS.REMOVE_APPLET,
      });
      dispatch({
        reference: `/@${oldUsername}`,
        type: REDUX_ACTIONS.REMOVE_REFERENCE,
      });
      dispatch({
        oldOwnerName: oldUsername,
        ownerName: user._meta.username,
        type: REDUX_ACTIONS.UPDATE_APPLETS_OWNERNAME,
      });
    }

    history.push(`/@${user._meta.username}`);
    notify({ message: 'Username successfully updated', type: 'success' });
  }).catch(() => {
    notify({ message: 'Something went wrong', type: 'failure' });
  });
};
export default compose(
  withNotifications,
  mapProps(({ user, ...props }) => ({
    oldUsername: (user._meta.username || null),
    user,
    ...props,
  })),
  connect(),
  withState('user', 'setUser', ({ user }) => user),
  withState('hint', 'setHint', ({ hint }) => hint),
  withState('isValid', 'setValid', false),
  withHandlers({
    onValueChange,
    validationHandler,
    saveHandler,
  }),
);

import React from 'react';
import PropTypes from 'prop-types';
import {
  Input, LinearProgress, FormHelperText, FormControl,
} from '@mui/material';
import { useUsernameUpdater } from './use-username-updater';

function UsernameInput({
  value: val, hint, onChange, onValidate,
}) {
  const { value, changeHandler } = useUsernameUpdater(val, onValidate, onChange);
  return (
    <FormControl
      variant="standard"
      error={hint.error}
      aria-describedby="username-input-text"
      fullWidth
    >
      <Input id="username-input" value={value} onChange={(e) => changeHandler(e)} placeholder="Enter Username" />
      {hint.message
        ? <FormHelperText id="username-input-text">{hint.message}</FormHelperText>
        : <LinearProgress style={{ margin: '6px 0', height: 8 }} />}

    </FormControl>
  );
}

UsernameInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  hint: PropTypes.shape({
    error: PropTypes.bool,
    message: PropTypes.string,
  }),
};

UsernameInput.defaultProps = {
  value: '',
  hint: {
    error: false,
    message: '',
  },
};

export default UsernameInput;

import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  container: {
    maxWidth: '1024px',
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexWrap: 'nowrap',
    },
  },
  header: {
    gridArea: 'leftSide',
    padding: theme.spacing(0.5),
    [theme.breakpoints.up('sm')]: {
      '&:only-child': {
        position: 'static',
        maxWidth: '100%',
      },
    },
    '& >:last-child': {
      margin: 'auto 2px',
    },
  },
  soleHeader: {
    gridArea: 'top',
  },
  ancestors: {
    display: 'flex',
    // marginBottom: '8px',
    flexWrap: 'wrap',
    '& > *': {
      flexGrow: 1,
      minWidth: '0',
      flexBasis: '320px',
      margin: '0 2px 4px 2px',
    },
  },
  content: {
    gridArea: 'main',
    padding: '8px',
    flexGrow: '9999',
    [theme.breakpoints.up('sm')]: {
      minWidth: 'calc(100% - 320px)',
    },
  },
  layoutSwitcher: {
    marginBottom: '0.6rem',
  },
}));

import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';
import SingleWidget from './single';
import WidgetList from './widget-list';

function WidgetView({ widget }) {
  const StyledWidgetList = widget.list && widget.list.enabled
    && withStyles({
      root: { ...(widget.styles && widget.styles.root) },
      list: { ...(widget.list && widget.list.styles) },
    })(WidgetList);
  return (
    StyledWidgetList
      ? <StyledWidgetList id={widget.key} rootWidget={widget} />
      : <SingleWidget widget={widget} />
  );
}

WidgetView.propTypes = {
  widget: PropTypes.shape({
    styles: PropTypes.shape({
      root: PropTypes.shape({ }),
    }),
    list: PropTypes.shape({
      enabled: PropTypes.bool,
      styles: PropTypes.shape({ }),
    }),
    key: PropTypes.string,
  }).isRequired,
};

export default WidgetView;

import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Add as AddIcon } from '@mui/icons-material';
import React from 'react';
import { Link } from 'packages/use-navigation/navigation-context';
import useLocalOwnershipCheck from 'packages/auth/use-local-owner-ship-check';
import { useLocalApplet } from 'packages/db/with-local-item';
import createUrl from 'packages/extensions/utils/create-url';

type Applet = {
  _meta: {
    owner: string,
  };
}

const useStyles = makeStyles({
  button: { margin: '8px 8px 0 8px', minWidth: '100px' },
  buttonWrapper: { display: 'flex', flexWrap: 'wrap' },
});

function ButtonLink(props: any) {
  return (
    <Button
      component={Link}
      color="primary"
      {...props}
    />
  );
}

function CreateButtons({ applet }: { applet: Applet; }) {
  const classes = useStyles();
  return (
    <div className={classes.buttonWrapper}>
      <ButtonLink to={`/${createUrl(applet)}/~create/Link`} className={classes.button}>
        <AddIcon />
        Link
      </ButtonLink>
      <ButtonLink
        to={`/${createUrl(applet)}/~create/Collection`}
        className={classes.button}
      >
        <AddIcon />
        Collection
      </ButtonLink>
    </div>
  );
}

function Create({ id }: { id: string }) {
  const userOwnsApplet = useLocalOwnershipCheck(id);
  const applet = useLocalApplet(id);
  if (userOwnsApplet && applet) {
    return <CreateButtons applet={applet} />;
  }
  return null;
}

export default Create;

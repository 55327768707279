import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@mui/material';
import WidgetView from 'packages/view-widgets/widgets/widget';
import Widgets from 'packages/view-widgets/widgets';
import db from 'packages/db';
import useLocalOwnershipCheck from 'packages/auth/use-local-owner-ship-check';
import { useLocalApplet } from 'packages/db/with-local-item';
import AddNew from './create';
import Header from './header';
import LayoutSwitcher, { getLayout } from './layout-switcher';
import useStyles from './styles';
import { useHasContentCheck } from './contents/with-contents-check';

const defaultLayoutConfig = { default: 'panel-list-item', sm: 'list-card' };

function LinkPage({ id }) {
  const applet = useLocalApplet(id);
  const classes = useStyles();
  const singleWidgetConfig = {
    key: id,
    view: 'card',
    viewProps: {
      elevation: 1,
    },
  };
  const layoutOptions = {
    ...defaultLayoutConfig,
    ...((applet && applet._meta.layout) || {}),
  };
  const layout = getLayout(id, layoutOptions);

  const handleLayoutConfigChange = (value) => {
    db.batch([{
      id,
      data: {
        ...applet,
        _meta: {
          ...applet._meta,
          layout: value,
        },
      },
    }]);
  };
  const userOwnsApplet = useLocalOwnershipCheck(id);
  const hasContent = useHasContentCheck(id);

  return (
    <>
      <div className={hasContent ? classes.header : classes.soleHeader}>
        <div className={classes.ancestors}>
          <Header id={id} />
        </div>
        <WidgetView widget={singleWidgetConfig} />
        <AddNew id={id} />
      </div>
      {hasContent && (
        <div className={classes.content}>
          {userOwnsApplet
            && (
              <Paper className={classes.layoutSwitcher}>
                <LayoutSwitcher value={layoutOptions} onChange={handleLayoutConfigChange} />
              </Paper>
            )}
          <Widgets widgets={layout} />
        </div>
      )}
    </>
  );
}
LinkPage.propTypes = {
  id: PropTypes.string.isRequired,
};
export default React.memo(LinkPage);

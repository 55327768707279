import React from 'react';
import { Link } from 'packages/use-navigation/navigation-context';
import { Button, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';

const styles = theme => ({
  root: {
    gridArea: 'top',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  container: {
    width: 'fit-content',
    minWidth: 256,
    maxWidth: '100%',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
  },
  button: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
});

const useStyles = makeStyles(styles);

function RedirectLinks() {
  const classes = useStyles();
  return (
    <Paper className={classes.root}>
      <div className={classes.container}>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/sign-up"
          className={classes.button}
        >
          Sign up
        </Button>
        <Button
          variant="outlined"
          color="primary"
          component={Link}
          to="/login"
          className={classes.button}
        >
          Login
        </Button>
      </div>
    </Paper>
  );
}

export default RedirectLinks;

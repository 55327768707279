import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';
import listCardItemLoader from '../../extensions/loader/views/list-card-item';
import styles from '../../extensions/styles/views/list-card-item';
import Base from './base';

function UnstyledListCardItem({ applet, classes, ...otherProps }) {
  const { name, image } = applet;
  return (
    <Base
      imageURL={image && image.contentURL}
      title={name}
      classes={classes}
      {...otherProps}
    />
  );
}
UnstyledListCardItem.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
  }).isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export const DefaultListCardItem = withStyles(styles)(UnstyledListCardItem);

function ListCardItem(props) {
  const { applet } = props;
  const DefaultComponent = useCallback(() => <DefaultListCardItem {...props} />, [props]);
  const Component = listCardItemLoader(applet.type, DefaultComponent);
  return <Component {...props} />;
}

ListCardItem.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    type: PropTypes.string,
  }),
};

ListCardItem.defaultProps = {
  applet: {
    name: undefined,
    description: undefined,
  },
};

export default ListCardItem;

import db from '../../../db';
import cleanApps from './apps';

export default async (_oldUsername, _newUsername) => {
  let userAppDoc;
  try {
    userAppDoc = await db.get(`users/${_oldUsername.toUpperCase()}`);
  } catch {
    userAppDoc = null;
  }
  if (userAppDoc === null) {
    return [];
  }

  const cleanedUpApps = await cleanApps(_oldUsername.toUpperCase(), _newUsername.toUpperCase());

  return [
    {
      id: _oldUsername.toUpperCase(),
      action: 'delete',
      collection: 'users',
    },
    {
      id: _newUsername.toUpperCase(),
      action: 'create',
      data: {
        uid: userAppDoc.uid,
        _meta: {
          owner: userAppDoc._meta.owner,
          updated: Math.round(Date.now() / 1000),
        },
      },
      collection: 'users',
    },
    ...cleanedUpApps,
  ];
};

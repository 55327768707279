import IModel from 'packages/model/interface';

type WatchCacheOptions = {
  id: string;
  callback: (data: any) => any;
};

function watchCache(db: IModel, { id, callback }:WatchCacheOptions) {
  return db.watchPath(`applets/${id}/cache`, callback);
}

export default watchCache;

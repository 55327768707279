import compose from 'recompose/compose';
import withProps from 'recompose/withProps';
import setPropTypes from 'recompose/setPropTypes';
import PropTypes from 'prop-types';

const qualifyUrl = (url) => {
  const originUri = window.location.origin.match(/\d*?/).input;
  return url.replace(originUri, '');
};

export default compose(
  setPropTypes({
    applet: PropTypes.shape({}).isRequired,
    url: PropTypes.string.isRequired,
  }),
  withProps(({ applet, url }) => ({
    applet,
    url: qualifyUrl(url),
  })),
);

import withWidgets from '../with-widgets';

type WidgetLoaderProps = {
  widgets: any;
  onItemFetched: (widgets: any) => any;
};

function Loader({ widgets, onItemFetched }: WidgetLoaderProps) {
  if (onItemFetched) onItemFetched(widgets);
  return null;
}

export default withWidgets(Loader);

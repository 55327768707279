import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import expansionPanelLoader from '../../extensions/loader/views/expansion-panel';
import BaseExpansionPanel from './base';
import { useContextualAppletURL } from '../containers/create-url';

const useDefaultAction = (applet) => {
  const appletURL = useContextualAppletURL(applet, 'applet');
  const { actions } = applet;
  if (actions) { return actions; }
  const originUri = window.location.origin.match(/\d*?/).input;
  return [{
    target: `${originUri}${appletURL}`,
    name: 'More',
  }];
};
export function DefaultExpansionPanel({ applet, ...otherProps }) {
  const {
    name, description, image, logo,
  } = applet;
  const avatar = (logo && logo.contentURL) || (image && image.contentURL);
  return (
    <BaseExpansionPanel
      avatar={avatar}
      primary={name}
      secondary={description}
      actions={useDefaultAction(applet)}
      {...otherProps}
    />
  );
}
DefaultExpansionPanel.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    logo: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    actions: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      target: PropTypes.string,
    })),
  }),
};

DefaultExpansionPanel.defaultProps = {
  applet: {
    description: '',
    actions: [],
  },
};

function AccordionItem(props) {
  const { applet } = props;
  const DefaultComponent = useCallback(() => <DefaultExpansionPanel {...props} />, [props]);
  const Component = expansionPanelLoader(applet.type, DefaultComponent);
  return <Component {...props} />;
}

AccordionItem.propTypes = {
  applet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    image: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    logo: PropTypes.shape({
      contentURL: PropTypes.string,
      height: PropTypes.number,
      width: PropTypes.number,
    }),
    actions: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      target: PropTypes.string,
    })),
    type: PropTypes.string.isRequired,
  }),
};

AccordionItem.defaultProps = {
  applet: {
    description: undefined,
    actions: [],
  },
};

export default AccordionItem;

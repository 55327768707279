import React from 'react';
import { Paper, LinearProgress } from '@mui/material';
import Loaders from '../..';

export function Loading() {
  return (
    <Paper style={{ padding: 16 }}>
      <LinearProgress />
      <LinearProgress />
    </Paper>
  );
}

export const getExpansionPanelByType = (type, DefaultComponent) => {
  if (Loaders[type] && Loaders[type].views.expansionPanel) {
    return Loaders[type].views.expansionPanel();
  }
  return DefaultComponent || Loaders.Thing.views.expansionPanel();
};

const loadView = (type, DefaultComponent) => getExpansionPanelByType(type, DefaultComponent);
export default loadView;

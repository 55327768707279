const getWidgetsConfig = (config, deviceSize) => {
  const {
    xl, lg, md, sm, _meta, key, ...xs
  } = config;
  if (deviceSize === 'xs') return { ...xs, key };
  let partial = config[deviceSize];
  if (partial === undefined && deviceSize === 'sm') {
    partial = getWidgetsConfig(config, 'xs');
  } else if (partial === undefined && deviceSize === 'md') {
    partial = getWidgetsConfig(config, 'sm');
  } else if (partial === undefined && deviceSize === 'lg') {
    partial = getWidgetsConfig(config, 'md');
  } else if (partial === undefined && deviceSize === 'xl') {
    partial = getWidgetsConfig(config, 'lg');
  } else if (partial === undefined) {
    partial = getWidgetsConfig(config, 'xs');
  }
  return { ...partial, key };
};

export default getWidgetsConfig;

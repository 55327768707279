import { debounce } from 'lodash';

const hints = {
  taken: {
    error: true,
    message: 'This username is not available',
  },
  available: {
    error: false,
    message: 'This username is available',
  },
  belowLength: {
    error: true,
    message: ' The username should be atleast 3 characters',
  },
};

const validateUsername = (db, username, callback) => {
  if (username.length < 3) {
    callback(hints.belowLength);
  } else {
    db.check.usernameIsAvailable(username)
      .then((available) => {
        if (available) {
          callback(hints.available);
        } else {
          callback(hints.taken);
        }
      });
  }
};
export default debounce(validateUsername, 2000);

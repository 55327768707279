import React, { useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import compose from 'recompose/compose';
import REDUX_ACTIONS from '../redux/constants';
import ERRORS from '../redux/local-error-applets';
import onlyWithPropAsRequired from '../only-with-prop-as-required';

type DispatchNotFoundParmas = {
  appletId: string | undefined;
  appId: string | undefined;
  username: string | undefined;
  dispatch: (a: any) => void;
  ownerName: string | undefined;
};

function dispatchTheNotFoundApplet({
  appletId,
  appId,
  username,
  dispatch,
  ownerName,
}: DispatchNotFoundParmas) {
  let notFoundId = appletId && appletId !== appId ? ERRORS.not_found_applet.id : null;
  notFoundId = appId && !notFoundId ? ERRORS.not_found_app.id : notFoundId;
  notFoundId = username && !notFoundId ? ERRORS.not_found_user.id : notFoundId;
  let realAppletId = appletId && appletId !== appId ? appletId.toUpperCase() : undefined;
  realAppletId = appId && !realAppletId ? appId.toUpperCase() : realAppletId;
  dispatch({
    type: REDUX_ACTIONS.SET_REFERENCE,
    id: notFoundId,
    applet: {
      _meta: {
        _ownerName: ownerName ? ownerName.toUpperCase() : undefined,
        _appId: appId ? appId.toUpperCase() : undefined,
        _appletId: realAppletId,
        _username: username ? username.toUpperCase() : undefined,
      },
      type: username ? 'User' : undefined,
    },
  });
}

type CreateParamsParams = {
  username: string | undefined;
  ownerName: string | undefined;
  appId: string | undefined;
  appletId: string | undefined;
};

function createparams({
  username, ownerName, appId, appletId,
}: CreateParamsParams) {
  const params: any = {};
  if (username) {
    params.username = username;
    params._username = username.toUpperCase();
  }
  if (ownerName) {
    params.ownerName = ownerName;
    params._ownerName = ownerName.toUpperCase();
  }
  if (appId) {
    params.appId = appId;
    params._appId = appId.toUpperCase();
  }
  if (appletId) {
    params.appletId = appletId;
    params._appletId = appletId.toUpperCase();
  }
  if (appId && !appletId) {
    params.appletId = appId;
    params._appletId = appId.toUpperCase();
  }

  return params;
}
type UserAppletPathParams = {
  username: string;
  ownerName?: string;
  appId?: string;
  appletId?: string;
}

type AppletPathParams = {
  ownerName: string;
  appId: string;
  appletId: string;
  username?: string;
}

type PathParams<T> = T extends UserAppletPathParams ? UserAppletPathParams : AppletPathParams;

export function useAppletKeyFromPath<T>({
  username, ownerName, appId, appletId,
}: PathParams<T>) {
  const dispatch = useDispatch();
  const appletKey = useSelector((state) => {
    const { references } = state as {references: {[any: string]: string}};
    let reference = '/@';
    reference = `${reference}${username ? username.toUpperCase() : ''}`;
    reference = `${reference}${ownerName ? ownerName.toUpperCase() : ''}`;
    reference = `${reference}${appId ? `/${appId.toUpperCase()}` : ''}`;
    if (appletId !== appId) {
      reference = `${reference}${appletId ? `/${appletId.toUpperCase()}` : ''}`;
    }
    return references[reference];
  }, shallowEqual);

  useEffect(() => {
    const params = createparams({
      username,
      ownerName,
      appId,
      appletId,
    });

    async function getKey() {
      try {
        const result = await fetch(`/api/fetch-applet-key?${new URLSearchParams(params)}`);
        const statusCode = result.status;
        const { key: id } = await result.json();

        if (id && id !== appletKey) {
          dispatch({
            type: REDUX_ACTIONS.SET_REFERENCE,
            id,
            applet: {
              _meta: params,
            },
          });
        } else if (statusCode === 404) {
          dispatchTheNotFoundApplet({
            appletId,
            appId,
            username,
            dispatch,
            ownerName,
          });
        } else if (!appletKey) {
          dispatchTheNotFoundApplet({
            appletId,
            appId,
            username,
            dispatch,
            ownerName,
          });
        }
      } catch (error) {
        console.error(error);
      }
    }

    getKey();
  }, [appId, appletId, appletKey, dispatch, ownerName, username]);
  return appletKey;
}

const withAppletKey = (C: any) => (function (props: any) {
  const {
    username, ownerName, appId, appletId,
  } = props;
  const appletKey = useAppletKeyFromPath({
    username,
    ownerName,
    appId,
    appletId,
  });
  return <C {...props} id={appletKey} />;
});

export default compose(withAppletKey, onlyWithPropAsRequired('id'));

import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import withState from 'recompose/withState';
import { Tabs } from '@mui/material';
import withLocalWidgets from '../../../db/with-local-widgets';
import MenuItem from './item';

function MenuItems({ widgets, value, setValue }) {
  return (
    <Tabs value={value}>
      {widgets.map(({ key }, index) => {
        const reactKey = `widgetkey-${key}`;
        return (
          <MenuItem
            key={reactKey}
            id={key}
            setActive={() => { setValue(index); }}
          />
        );
      })}
    </Tabs>
  );
}

MenuItems.propTypes = {
  widgets: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
  })).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.bool, PropTypes.number,
  ]).isRequired,
  setValue: PropTypes.func.isRequired,
};

export default compose(
  withState('value', 'setValue', false),
  withLocalWidgets,
)(MenuItems);

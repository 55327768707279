import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '../list-item';
import PanelListItem from '../expansion-panel';
import Card from '../card';
import MiniCard from '../mini-card';
import ListCardItem from '../list-card-item';
import BaseButton from '../button';
import ImageViewer from '../image-view';
import AppMenu from '../app-menu';
import ImageGrid from '../image-grid';

const views = {
  'panel-list-item': PanelListItem,
  'list-item': ListItem,
  'list-card': ListCardItem,
  'mini-card': MiniCard,
  card: Card,
  button: BaseButton,
  image: ImageViewer,
  'app-menu': AppMenu,
  'image-grid': ImageGrid,
};
function DynamicItem({
  view, applet, ...rest
}) {
  const View = views[view] || ListItem;
  if (Object.keys(applet).length === 0) return null;
  return <View {...rest} applet={applet} />;
}

DynamicItem.propTypes = {
  view: PropTypes.string,
  applet: PropTypes.shape({ }).isRequired,
};
DynamicItem.defaultProps = {
  view: 'list-item',
};

export default DynamicItem;

import React from 'react';
import { useSelector } from 'react-redux';

const isParentKey = (key, applet) => applet && applet._meta.parent === key;
const isAppKey = (key, applet) => applet && applet._meta.app === key;

const checkHasContent = (id, widgets, applets) => {
  let has = false;
  const mainApplet = applets[id];

  if (!widgets) return has;
  const contentWidgets = Object.values(widgets).filter(({ key }) => key !== id
    && !isParentKey(key, mainApplet) && !isAppKey(key, mainApplet));
  if (contentWidgets.length) {
    has = true;
  }
  return has;
};

export function useHasContentCheck(id) {
  const hasContent = useSelector((state) => {
    const widgets = state.widgets[id];
    const { applets } = state;
    return checkHasContent(id, widgets, applets);
  });
  return hasContent;
}

function withContentCheck(Component) {
  return function (props) {
    const { id } = props;
    const hasContent = useHasContentCheck(id);
    return <Component {...props} hasContent={hasContent} />;
  };
}

export default withContentCheck;

import React from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash/groupBy';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import getConfigByDeviceSize from './get-config-by-device-size';
import Widget from './widget';

/**
 * Be careful using this hook. It only works because the number of
 * breakpoints in theme is static. It will break once you change the number of
 * breakpoints. See https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level
 */
function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}

const WidgetsView = ({ widgets }) => {
  const width = useWidth();
  const widgetsByDeviceSize = widgets.map(widget => getConfigByDeviceSize(widget, width));
  const widgetsByGridArea = groupBy(widgetsByDeviceSize, ({ gridArea }) => gridArea || 'main');

  return Object.keys(widgetsByGridArea).map((gridArea) => {
    const reactKey = `widgets-view-grid-area${gridArea}`;
    return (
      <div key={reactKey} className={gridArea}>
        {
          widgetsByGridArea[gridArea].map((widget, index) => {
            const widgetkey = `${widgets.key}-widget-${index}`;
            return <Widget widget={widget} key={widgetkey} />;
          })
        }
      </div>
    );
  });
};

WidgetsView.propTypes = {
  widgets: PropTypes.arrayOf(PropTypes.shape({})),
};

WidgetsView.defaultProps = {
  widgets: [],
};

export default WidgetsView;

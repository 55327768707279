import { IApplet } from 'packages/model/interface';
import { useDbContext } from 'App/context/db';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import REDUX_ACTIONS from '../redux/constants';

type useLiveAppletOptions = {
  onError?: () => void;
};
type StateTyoe = {
  applets: {
    [key: string]: IApplet;
  };
};

function useLiveApplet(id: string, options?: useLiveAppletOptions) {
  const dispatch = useDispatch();

  const localApplet :IApplet | undefined = useSelector((state:StateTyoe) => state.applets[id]);
  const db = useDbContext();
  function onUpdate(applet: IApplet) {
    if (localApplet && localApplet._meta.updated !== applet._meta.updated) {
      dispatch({
        type: REDUX_ACTIONS.SET_APPLET,
        id,
        applet,
      });
    }
  }

  useEffect(() => {
    if (options && options.onError) {
      return db.watchApplet(id, onUpdate, options.onError);
    }
    return db.watchApplet(id, onUpdate);
  });
}
export default useLiveApplet;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import WidgetView from 'packages/view-widgets/widgets/widget';
import withItem from 'packages/db/with-item';

function Header({ applet }) {
  let keys = [
    applet.type === 'User' ? null : applet._meta.owner,
    applet._meta.parent ? applet._meta.app : null,
    applet._meta.parent,
  ].filter(key => !!key);

  if (keys.length < 1) {
    keys = [];
  }

  return (
    <>
      {
        Array.from(new Set(keys)).map(key => (
          <WidgetView
            key={key}
            widget={{ key }}
          />
        ))
      }
    </>
  );
}

Header.propTypes = {
  applet: PropTypes.shape(),
};

Header.defaultProps = {
  applet: {
    _meta: {},
  },
};

export default withItem(Header);

import React from 'react';
import PropTypes from 'prop-types';
import { Tab } from '@mui/material';
import { useLocalApplet } from 'packages/db/with-local-item';
import useNavigation from 'packages/use-navigation';
import { useContextualAppletURL } from '../../../containers/create-url';

function MenuItem({ id, setActive }) {
  const applet = useLocalApplet(id);
  const url = useContextualAppletURL(applet, 'applet');
  const { Link } = useNavigation();

  return (
    <Tab
      component={Link}
      to={url}
      action={() => {
        if (window && window.location && window.location.pathname === url) { setActive(); }
      }}
      label={applet.name}
    />
  );
}

MenuItem.propTypes = {
  id: PropTypes.string.isRequired,
  setActive: PropTypes.func.isRequired,
};
export default MenuItem;

import React, { useEffect } from 'react';
import orderBy from 'lodash/orderBy';
import isEqual from 'lodash/isEqual';
import { useDbContext } from 'App/context/db';
import { useDispatch, useSelector } from 'react-redux';
import REDUX_ACTIONS from '../redux/constants';

export function useWidgets(id) {
  const db = useDbContext();
  const dispatch = useDispatch();
  const widgets = useSelector(state => state.widgets[id]);
  useEffect(() => {
    db.query(`applets/${id}/widgets`, [])
      .then((querySnapshot) => {
        if (querySnapshot && querySnapshot.length > 0) {
          const results = querySnapshot.map(docSnapShot => docSnapShot.data);
          const orderedWidgets = orderBy(results, 'order');
          if (!isEqual(orderedWidgets, widgets)) {
            dispatch({
              type: REDUX_ACTIONS.SET_WIDGETS,
              id,
              widgets: orderedWidgets,
            });
          }
        }
      });
  });
  return widgets;
}

function withWidgets(Component) {
  return function (props) {
    const { id } = props;
    const widgets = useWidgets(id);
    return <Component {...props} widgets={widgets} />;
  };
}

export default withWidgets;

import db from '../../../../db';
import cleanAppApplets from './app-applets';

async function userAppsCleanup(_oldUsername, _newUsername) {
  const userAppsRawDocs = await db.query(
    `users/${_oldUsername}/apps`,
    [],
  );

  if (!userAppsRawDocs.length) {
    return [];
  }

  const tobeDeletedApps = [...userAppsRawDocs]
    .map(({ id }) => ({
      id,
      collection: `users/${_oldUsername}/apps`,
      action: 'delete',
    }));

  const toBeCreatedApps = [...userAppsRawDocs]
    .map(({ id, data }) => ({
      id,
      action: 'create',
      data: {
        appKey: data.appKey,
        _meta: {
          owner: data._meta.owner,
          updated: Math.round(Date.now() / 1000),
        },
      },
      collection: `users/${_newUsername}/apps`,
    }));

  const [toBeCleanedAppAppletsPromise] = tobeDeletedApps
    .map(async ({ id }) => {
      const docsResults = await cleanAppApplets(_oldUsername, _newUsername, id);
      return docsResults;
    });

  const toBeCleanedAppApplets = await toBeCleanedAppAppletsPromise;
  return [...tobeDeletedApps, ...toBeCreatedApps, ...toBeCleanedAppApplets];
}
export default userAppsCleanup;

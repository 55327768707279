const createURL = ({
  username, ownerName, appId, appletId,
}) => {
  if (username) {
    return `/@${username}`;
  }
  if (appId && ownerName && appId === appletId) {
    return `/@${ownerName}/${appId}`;
  }
  if (appId && appletId && ownerName) {
    return `/@${ownerName}/${appId}/${appletId}`;
  }
  return '';
};
export default createURL;
